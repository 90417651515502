import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import MyPortableText from "../components/MyPortableText";
import {graphql} from "gatsby";

export const query = graphql`
    {
        allSanityImpressum {
    edges {
      node {
        _rawBody
      }
    }
  }
        }
    `;

const Impressum = ({data}) => {

    const impressum = data.allSanityImpressum.edges[0].node;

    return (
        <Layout newsletter={false} page={"impressum"}>
            <SEO title={"Impressum"}/>
            <div className="basepage__outer flexmobile">
                <div className="basepage__inner impressum">
                    <div className="upper">
                        <h4 className={"uppercase"}>Impressum</h4>
                        <MyPortableText value={impressum._rawBody}></MyPortableText>
                    </div>

                    <div className="lower">
                        <div className="impressum__credit__container">
                            <a href={"https://onimo-studios.com/"} className="hover-moveup studiolink">
                                <p>
                                    Design von <span className="underline">ONIMO Studios</span>
                                </p>
                            </a>
                        </div>
                        <div className="impressum__credit__container">
                            <a href={"https://bureabuero.com/"} className="hover-moveup studiolink">
                                <p>
                                    Website von <span className="underline">BUREAU BÜRO</span>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Impressum;
